import Vue from 'vue';
import axios from 'axios';

// HTTP Axios
Vue.use(axios)

export default axios.create({
    baseURL: 'https://cdtygw2c04.execute-api.sa-east-1.amazonaws.com',
    headers: {
        'Content-Type': 'application/json'
    }
});