<template>
    <div id="app">
        <div class="pipeform-menu">
            <img class="pipeform-whatsapp-logo" alt="PipeRun Logo" src="./assets/logo_piperun_preta.png">
        </div>
        <div class="pipeform-column-form">
            <div class="pipeform-column-form-title">
                <h2>Formulário de WhatsApp CRM PipeRun</h2>
                <p>Preencha os campos abaixo para gerar o botão de WhatsApp para seu site.</p>
            </div>
            <Form />
        </div>
    </div>
</template>

<script>
import Form from './components/Form.vue'

export default {
    name: 'App',
    components: {
        Form
    }
}
</script>

<style>
    body {
        margin: 0;
        background-color: rgba(100, 100, 100, .14);
    }

    *, ::before, ::after {
        box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-family: Rubik, Helvetica, Arial, sans-serif;
    }

    a {
        color: #FF5252;
        text-decoration: none;
    }

    a:hover {
        color: #FF285B;
    }

    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2C3E50;
    }

    .pipeform-menu {
        background-color: #FFFFFF;
        box-shadow: 2px 5px 5px rgba(160, 160, 160, .14);
    }

    .pipeform-whatsapp-logo {
        width: 150px;
        margin: 10px 0;
        height: auto;
    }

    .pipeform-grid {
        margin: 0;
        padding: 0;
        text-align: left;
    }

    .pipeform-column-form {
        margin: 50px 30%;
        background-color: #FFFFFF;
        justify-content: center;
        overflow: auto;
    }

    .pipeform-column-form-title {
        padding: 2em 2em 0 2em;
        margin: 0 3em;
    }

    @media screen and (max-width: 1280px) {
        .pipeform-column-form {
            min-height: 800px;
        }
    }

    @media screen and (max-width: 800px) {
        body {
            background-color: #FFFFFF;
        }
        
        .pipeform-column-form {
            margin: 0 5% !important;
            background-color: initial;
            min-width: initial;
        }

        .pipeform-grid {
            background: none;
        }

        .pipeform-column-form-title {
            padding: 0;
            margin: 0 1em;
        }

        .pipeform-whatsapp {
            margin: 1em 1em;
            padding: 2em 2em;
        }
    }
</style>
