<template>
    <div class="pipeform-whatsapp">
        <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="fullPage"></loading>
        <h3>Criar Botão WhatsApp</h3>
        <form @submit.prevent="send" v-show="!script">
            <div class="pipeform-field">
                <input :class="['pipeform-input', ($v.top_message.$error) ? 'has-error' : '']" type="text" v-model="top_message" placeholder="Mensagem do topo">
                <span class="pipeform-help">Informe qual a mensagem deseja mostrar para os clientes.</span>
                <div class="pipeform-alert-danger" v-show="$v.top_message.$error">
                    <p><strong>Atenção!</strong> Este campo é obrigatório.</p>
                </div>
            </div>
            <div class="pipeform-field">
                <the-mask :mask="['(##) ####-####', '(##) #####-####']" v-model="whatsapp_phone" :class="['pipeform-input', ($v.whatsapp_phone.$error) ? 'has-error' : '']" placeholder="(99)99999-9999"></the-mask>
                <span class="pipeform-help">Qual é o número de WhatsApp que deseja usar para receber os contatos.</span>
                <div class="pipeform-alert-danger" v-show="$v.whatsapp_phone.$error">
                    <p><strong>Atenção!</strong> Este campo é obrigatório.</p>
                </div>
            </div>
            <div class="pipeform-field">
                <input :class="['pipeform-input', ($v.email.$error) ? 'has-error' : '']" type="text" v-model="email" placeholder="E-mail que receberá os contatos">
                <span class="pipeform-help">Qual é o endereço de e-mail que receberá os contatos.</span>
                <div class="pipeform-alert-danger" v-show="$v.email.$error">
                    <p><strong>Atenção!</strong> Este campo é obrigatório.</p>
                </div>
            </div>
            <div class="pipeform-field">
                <input :class="['pipeform-input', ($v.name.$error) ? 'has-error' : '']" type="text" v-model="name" placeholder="Seu nome">
                <span class="pipeform-help">Informe seu nome completo.</span>
                <div class="pipeform-alert-danger" v-show="$v.name.$error">
                    <p><strong>Atenção!</strong> Este campo é obrigatório.</p>
                </div>
            </div>
            <button class="pipeform-button" type="submit">Gerar Botão WhatsApp</button>
            <span class="pipeform-privacy">Ao gerar seu botão de WhatsApp você concorda com a <a href="https://crmpiperun.com/legal/politica-de-privacidade/" target="_blank">política de privacidade</a> da PipeRun</span>
        </form>
        <div class="pipeform-script" v-show="script">
            <h4>Script Gerado</h4>
            <p>Agora é só copiar e colar no seu site para utilizar.</p>
            <div class="pipeform-layout-script">
                <p>
                    <code ref="pipeform_whatsapp_code">
                        {{ script_text }}
                    </code>
                </p>
                <button class="pipeform-button" style="margin-top: 0;" @click="copyScript">
                    Copiar Script
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// Axios
import http from '../plugins/axios'

// Sweet Alert
import SweetAlert from 'sweetalert2'

// Mask cellphone and email input.
import { TheMask } from 'vue-the-mask'

// Validate the form.
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'Form',
    mixins: [
        validationMixin
    ],
    components: {
        Loading,
        TheMask
    },
    data() {
        return {
            script: false,
            script_text: '',
            json: '',
            isLoading: false,
            fullPage: true,
            name: null,
            email: null,
            whatsapp_phone: null,
            top_message: null
        }
    },
    validations: {
        name: { required },
        email: { required },
        whatsapp_phone: { required },
        top_message: { required }
    },
    methods: {
        send() {
            this.isLoading = true
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.isLoading = false
            } else {
                var data = {
                    "name": this.name,
                    "email": this.email,
                    "whatsapp_phone": this.whatsapp_phone,
                    "top_message": this.top_message
                }

                this.json = JSON.stringify(data)

                this.script_text = '<script type="text/javascript">' + 
                'window.piperun_data = ' + this.json + ';<' + '/' + 'script>' + 
                '<script src="'+ window.location + 'script.min.js" type="text/javascript"><' + '/' + 'script>'

                this.sendMail(this.email)

                setTimeout(()=> {
                    this.isLoading = false
                    this.script = true
                }, 3000)
            }

        },
        onCancel() {
            console.log('Usuário cancelou o carregamento.')
        },
        copyScript() {
            this.selectText(this.$refs.pipeform_whatsapp_code);
            document.execCommand('copy');

            SweetAlert.fire({
                title: 'Sucesso',
                text: 'Script copiado para a área de transferência',
                icon: 'success',
                confirmButtonColor: '#3085D6'
            })
        },
        selectText(element) {
            var range;
            if (typeof document.selection === "function") {
                // Internet Explorer
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (typeof window.getSelection === "function") {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        },
        sendMail(email) {
            var data = {
                template: 2,
                to: email,
                subject: 'Olá, você gerou o seu botão de WhatsApp',
                name: this.name,
                email: email,
                script: this.script_text
            };

            http.post('/', data)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style>
    .pipeform-whatsapp {
        border-radius: 5px;
        border: 1px solid #AAAAAA;
        padding: 2em 3em;
        margin: 2em 5em;
    }

    .pipeform-field {
        display: block;
    }

    .pipeform-input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #AAAAAA;
        color: #2C3E50;
        font-family: inherit;
        padding: .4em;
        margin-top: 1em;
        width: 100%;
    }

    .pipeform-input:focus {
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #FF5252;
    }

    .pipeform-input::placeholder {
        color: #AAAAAA;
        font-family: inherit;
    }

    .pipeform-help {
        font-size: 10px;
    }

    .pipeform-button {
        outline: none;
        color: #FFFFFF;
        background: linear-gradient(to right, #FF5252, #FF285B);
        border: 1px solid #FF5252;
        border-radius: 5px;
        padding: 1em;
        cursor: pointer;
        width: 100%;
        font-family: Rubik, sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        margin-top: 2em;
    }

    .pipeform-button:hover {
        background: linear-gradient(to right, #FF285B, #FF5252);
    }

    .pipeform-privacy {
        font-size: 9px;
    }

    .pipeform-alert-danger {
        margin-top: 20px;
        background-color: #F2DEDE;
        color: #A94442;
        border: 1px solid #EBCCD1;
        border-radius: 5px;
        padding: 5px 15px;
    }

    .pipeform-layout-script > p {
        padding: 2px 4px;
        background-color: #212121;
    }

    code {
        color: #F78C6C;
        word-break: break-all;
        padding: 0 !important;
    }
</style>